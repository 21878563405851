import ApplicationController from '../application_controller'
import Swiper from 'swiper/bundle';

export default class extends ApplicationController {
  static targets = ['input']
  
  connect(){

    this.inputTarget.value = 150;
    
    this.swiper = new Swiper(".mySwiper", {
      direction: 'vertical',
      slidesPerView: 3,
      spaceBetween: 0,
      freeMode: true,
      pagination: false,
      initialSlide: 220 - this.inputTarget.value
    });

    const inputTarget = this.inputTarget;
    this.swiper.on('slideChange', function () {
      inputTarget.value = this.slides[this.activeIndex].dataset.value;
    });

  }

  changeInput(){
    this.swiper.slideTo(220 - parseInt(this.inputTarget.value));
  }

  
}