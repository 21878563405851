// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
if (process.env.NODE_ENV === 'production') console.log = function () {};

// require("turbolinks").start()
export const getTurbo = async () => {
  if (!window.Turbo) {
    const Turbo = require('@hotwired/turbo')
    await Turbo.start()
    window.Turbo = Turbo
  }
  return window.Turbo
}

import { Turbo, cable } from "@hotwired/turbo-rails"
window.Turbo = Turbo

require("@rails/ujs").start()
import Rails from '@rails/ujs';
import 'components/confirm'

import "components"

import { SVGInjector } from '@tanem/svg-injector'

require("@rails/activestorage").start()
require("channels")
require("packs/global-functions")
// require("packs/ga")

//= require i18n
// require("bulma/bulma");
// import bulmaCalendar from "bulma-extensions/bulma-calendar/dist/js/bulma-calendar.js"
// import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min.js';
// require("bulma-steps-component/bulma-steps");

// require("@creativebulma/bulma-tooltip");
// require("pesky_scrolly_stuff")
// require("typewriter")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import "../../assets/stylesheets/application.scss"

// import './some.scss'

require.context('../../images', true)
// require.context('../../../components', true, /\.scss$/)

// import I18n from 'i18n-js/index.js.erb'
// global.I18n = I18n;
// import I18n from "i18n-js"
// global.I18n = I18n;
// app/javascript/config/i18n.ts
import { I18n } from "i18n-js";
import translations from "locales.json";

// Fetch user locale from html#lang.
// This value is being set on `app/views/layouts/application.html.erb` and
// is inferred from `ACCEPT-LANGUAGE` header.
const userLocale = document.documentElement.lang;

export const i18n = new I18n();
i18n.store(translations);
i18n.defaultLocale = "en";
i18n.enableFallback = true;
i18n.locale = userLocale;
global.I18n = i18n;


import SmoothScroll from 'smooth-scroll';
// global.scroll = SmoothScroll;

import iziToast from 'izitoast/dist/js/iziToast';
global.iziToast = iziToast;

window.bulmaOptions = {
  autoParseDocument: false
}

import gtag from 'components/analytics'
global.gtag = gtag;

import Bulma from '@vizuaalog/bulmajs';
global.Bulma = Bulma;
import Navbar from '@vizuaalog/bulmajs/src/plugins/navbar';

import firebase from 'firebase';
global.firebase = firebase;

var firebaseConfig = {
  apiKey: "AIzaSyA_AEmieuaROechEEZapCZtXY61yI5359Y",
  authDomain: "skillcore-io.firebaseapp.com",
  projectId: "skillcore-io",
  storageBucket: "skillcore-io.appspot.com",
  messagingSenderId: "315439279147",
  appId: "1:315439279147:web:b94903fcf0b44ef999ce6e",
  measurementId: "G-MNQX8KEBZ0"
};
firebase.initializeApp(firebaseConfig);

// import Choices from "choices.js";

// import 'components/stonly'

import "controllers";

// require("smooth-scroll")
import 'trix/dist/trix.js';
var Trix = require("trix")
require("@rails/actiontext")

import "chartkick/chart.js"

//Change demo images
function changeDemoImages() {
  document.querySelectorAll('*[data-demo-src]').forEach(function (elem) {
      var newSrc = elem.getAttribute('data-demo-src');
      elem.setAttribute('src', newSrc);
  });

  document.querySelectorAll('*[data-demo-background]').forEach(function (elem) {
      var newBg = elem.getAttribute('data-demo-background');
      elem.setAttribute('data-background', newBg);
  });

  document.querySelectorAll('*[data-demo-href]').forEach(function (elem) {
      var newHref = elem.getAttribute('data-demo-href');
      elem.setAttribute('href', newHref);
  });
}

//Init attribute background images
function initBgImages() {
  if (document.querySelectorAll('.has-background-image').length) {
    document.querySelectorAll(".has-background-image").forEach(function (elem) {
          var bgImage = elem.getAttribute('data-background');
          if (bgImage !== undefined) {
            elem.style.backgroundImage = 'url("' + bgImage + '")';
          }
      }
    )
  }
};

const navbarScroll = function() {
  var currentScrollPos = window.pageYOffset;

  // 20 is an arbitrary number here, just to make you think if you need the prevScrollpos variable:

  var w = window.innerWidth;
  if(w >= 1024){ 
    document.querySelector("nav.navbar").classList.add('is-hidden-mobile');
    document.querySelector("div.webapp-navbar").style.display = 'initial';
    return;
  }

  if (currentScrollPos > 80) {
    // I am using 'display' instead of 'top':
    document.querySelector("nav.navbar").classList.remove('is-hidden-mobile');
    document.querySelector("div.webapp-navbar").style.display = 'initial';
    document.querySelector("body.mobile-focus.community:not(.onboard)").style.paddingTop = '60px';
  } else {
    document.querySelector("body.mobile-focus.community:not(.onboard)").style.paddingTop = '0px';
    document.querySelector("nav.navbar").classList.add('is-hidden-mobile');
    document.querySelector("div.webapp-navbar").style.display = 'inital';
  }
}

const openLink = function(link_visit) {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = link_visit;
  a.target = '_blank'
  a.click();
  document.body.removeChild(a);
}
global.openLink = openLink;

function initSingleAccordion() {
  var accordions = document.querySelectorAll('.single-accordion .accordion-header:not(.initialized)');

  accordions.forEach(function(accordion) {
    accordion.addEventListener('click', function() {
      var parentAccordion = this.closest('.single-accordion');

      if (parentAccordion.classList.contains('is-exclusive')) {
        if (this.classList.contains('is-active')) {
          this.classList.remove('is-active');
          this.nextElementSibling.style.display = 'none';
        } else {
          var activeHeaders = parentAccordion.querySelectorAll('.accordion-header.is-active');
          activeHeaders.forEach(function(header) {
            header.classList.remove('is-active');
          });

          var contentSections = parentAccordion.querySelectorAll('.accordion-content');
          contentSections.forEach(function(content) {
            content.style.display = 'none';
          });

          this.classList.add('is-active');
          this.nextElementSibling.style.display = 'block';
        }
      } else {
        this.classList.toggle('is-active');
        this.nextElementSibling.style.display = 
            this.nextElementSibling.style.display === 'none' ? 'block' : 'none';
      }
    });
    accordion.classList.add('initialized');
  });

  
  
}

global.readyFn = (function(event) {

  // if(typeof twttr != 'undefined'){
  //   twttr.events.bind(
  //     'rendered',
  //     function (event) {
  //       const loading = event.target.closest('.tweet-status').querySelector('.list-view-item');
  //       if(loading)
  //         loading.remove();
  //     }
  //   );
  // }
  
  // let myFile = Bulma('.file').data('file');
  // myFile.on('changed', function() {
  //     let filename = myFile.getFilename();
  // });

  if(document.querySelector("body.mobile-focus.community:not(.onboard)"))
    window.onscroll = navbarScroll;
  else
    window.onscroll = function(){};
  
  var scrollDefault = new SmoothScroll('a[href*="#"]', {});

  var mySVGsToInject = document.querySelectorAll('img.inject-me[src$=".svg"]');

  // Do the injection
  SVGInjector(mySVGsToInject)

  // if (window.hasOwnProperty('bulmaOptions')) {
  //   Bulma.traverseDOM()
  // } 
  // Bulma.traverseDOM();

  changeDemoImages();
  initBgImages();
  initSingleAccordion();

  window.addEventListener("trix-file-accept", function(event) {
    // event.preventDefault();
    // var attachment = event.attachment
    // if(attachment.file)
    // console.log(event.file)
    event.preventDefault();
  })
  // window.addEventListener("optimism:form:valid", function(event) {
  //   // console.log("asasas")
  // })

  // var $dropdowns = getAll('.has-dropdown:not(.is-hoverable)');

  // if ($dropdowns.length > 0) {
  //   $dropdowns.forEach(function ($el) {
  //     console.log("asasas")
  //     const func = (event) => {
  //       console.log($el.classList)
  //       event.stopPropagation();
  //       $el.classList.toggle('is-active');
  //     }
  //     $el.removeEventListener("click", func);
  //     $el.addEventListener('click', func, false);
      
  //   });

    
  // }

  document.addEventListener('click', function (event) {
    closeDropdowns(event);
  });

  function closeDropdowns(event) {

    getAll('.dropdown:not(.is-hoverable).is-active').forEach(function ($el) {
      if($el !== event.target && !$el.contains(event.target)) {
        $el.classList.remove('is-active');
      } 
    });
     
  }

  // Close dropdowns if ESC pressed
  document.addEventListener('keydown', function (event) {
    var e = event || window.event;
    if (e.keyCode === 27) {
      closeDropdowns();
    }
  });

  document.documentElement.classList.remove('is-clipped');

  // plugin.handler.parse(element);
  // const today = new Date();
  // const calendars = bulmaCalendar.attach('[type="date"]', {
  //   color: "primary", 
  //   type: "date", 
  //   showFooter: false,
  //   showHeader: false, 
  //   dateFormat: I18n.t("date.formats.datepicker"), 
  //   lang: I18n.locale, 
  //   showButtons: false
  // });

  // Loop on each calendar initialized
  // calendars.forEach(calendar => {
  //   // Add listener to date:selected event
  //   calendar.on('select', date => {
  //     console.log(new Date('2012-03-21'), new Date (2012, 2, 21)); 
  //     // console.log(date);
  //   });
  // });


  // Bulma.traverseDOM()
  let elements = document.querySelectorAll(".navbar, .tabs-wrapper, .file");

  Bulma.each(elements, (element) => {
    if(element.hasAttribute('data-bulma-attached')) {
      return;
    }

    let plugins = Bulma.findCompatiblePlugins(element);
    
    Bulma.each(plugins, (plugin) => {
      plugin.handler.parse(element);
      if(!element.hasAttribute('data-bulma-attached')) {
        element.setAttribute('data-bulma-attached', true);
      }
    });
  });

  // (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
  //   const $notification = $delete.parentNode;

  //   $delete.addEventListener('click', () => {
  //     $notification.parentNode.removeChild($notification);
  //   });
  // });

  function closeModal(el) {
    el.classList.remove('is-active');
    // this.closeModal(el)
  }

  function closeAllModals() {
    (document.querySelectorAll('.modal.is-active') || []).forEach(($modal) => {
      closeModal($modal);
    });
  }

  // Add a keyboard event to close all modals
  document.addEventListener('keydown', (event) => {
    const e = event || window.event;

    if (e.keyCode === 27) { // Escape key
      closeAllModals();
    }
  });
  
});

// import consumer from '../../channels/consumer'
// var connectionRestore = (function(){
//   consumer.disconnect()
//   consumer.connect()
  
// })

// document.addEventListener('cable-ready:after-morph', readyFn);
// document.addEventListener('stimulus-reflex:after', readyFn);
document.addEventListener('turbo:load', readyFn);
document.addEventListener("turbo:frame-load", readyFn);
// document.addEventListener('turbo:load', connectionRestore);
// document.addEventListener('DOMContentLoaded', ready);

// document.addEventListener('turbo:before-visit', function(){
//   if(document.querySelector(".pageloader"))
//     document.querySelector(".pageloader").classList.add('is-active')
// })
// // document.addEventListener('turbo:request-end', function(){
// //   document.querySelector(".pageloader.is-active").classList.remove('is-active')
// // })
// const hidePageloader = function(){
//   if(document.querySelector(".pageloader.is-active"))
//     document.querySelector(".pageloader.is-active").classList.remove('is-active') 
// }
// document.addEventListener('turbo:load', hidePageloader)
// document.addEventListener('cable-ready:after-morph', hidePageloader)
// document.addEventListener('stimulus-reflex:after', hidePageloader)


document.addEventListener('turbo:submit-end', function(event){
  event.target.querySelectorAll("[type='submit']").forEach((elem) => {
    Rails.enableElement(elem);
  })
  
});

document.addEventListener("turbo:frame-missing", async ({ target, detail: { fetchResponse } }) => {
  const { location, redirected, statusCode, responseHTML } = fetchResponse
  const response = { redirected, statusCode, responseHTML: await responseHTML }

  console.log("turbo:frame-missing", response);
})

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevents the default mini-infobar or install dialog from appearing on mobile
  e.preventDefault();
  // Save the event because you'll need to trigger it later.
  window.deferredPrompt = e;
  console.log(window.deferredPrompt)
})