import ApplicationController from './application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['fieldsContainer']
  
  connect(){
  }

  add(){
    let inputRef = this.fieldsContainerTarget.querySelector(".field-item")
    console.log(inputRef)
    if(inputRef.querySelector(".field-input").value.trim() != ""){
      let input = inputRef.cloneNode(true);
      input.querySelector(".field-input").value = "";
      this.fieldsContainerTarget.appendChild(input)
    }
    
  }

  // add(event) {
  //   event.preventDefault();
  //   event.currentTarget.insertAdjacentHTML(
  //     "beforebegin",
  //     this.templateTarget.innerHTML.replace(
  //       /__CHILD_INDEX__/g,
  //       new Date().getTime().toString()
  //     )
  //   );
  // }

  remove(event){
    if(this.fieldsContainerTarget.querySelectorAll(".field-input").length > 1)
      event.target.closest(".control").remove()
    
  }

  removeQuestion(event){
    if(this.fieldsContainerTarget.querySelectorAll(".field-input.question").length > 1)
      event.target.closest("turbo-frame").remove()
  }

  removeOption(event){
    event.target.closest(".option-item").remove()
  }

}