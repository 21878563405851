import ApplicationController from './application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['inputCredits', 'inputLimit', 'activeForm', 'categoryContainer', 'fieldsContainer']
  
  connect(){
    super.connect();
    if (this.hasInputCreditsTarget){
      IMask(this.inputCreditsTarget, {
        mask: Number,
        scale: 0, 
        min: 0,
        // max: 10000,
        thousandsSeparator: ''
      });
    }

    if(this.hasInputAmountTarget){
      IMask(this.inputLimitTarget, {
        mask: Number,
        scale: 0,         
        // max: 10000,
        thousandsSeparator: ''
      });
    }
  }

  pickCategory(event){
    event.preventDefault();
    event.stopPropagation();

    this.element.querySelector("#community_item_category").value = event.currentTarget.dataset.category;
    this.categoryContainerTarget.classList.add("is-hidden")
    this.fieldsContainerTarget.classList.remove("is-hidden")
    this.element.querySelectorAll("button[type=submit]").forEach((b) => {
      b.removeAttribute("disabled")
    });
  }

  pickNft(event){
    if(event.currentTarget.value > 0) {
      this.element.querySelector(".alert-nft-limit").classList.remove('is-hidden')
      this.element.querySelector(".community_item_limit_container").classList.add('is-hidden')
    } else {
      this.element.querySelector(".alert-nft-limit").classList.add('is-hidden')
      this.element.querySelector(".community_item_limit_container").classList.remove('is-hidden')
    }
  }

  toggleActive(){
    console.log("TOGGLE ACTIVE", this.activeFormTarget)
    this.stimulate('Admin::Items#toggleActive', this.activeFormTarget)
      .then(() => {
        iziToast.success({
          title: I18n.t("community.settings.items.items.toggle_active.success.title"),
          message: I18n.t("community.settings.items.items.toggle_active.success.message"),
          class: "notification is-light"
        });
      })
      .catch(() => console.log("ERROR"))
  }

  destroy(event) {
    event.preventDefault();
    event.stopPropagation();

    var controller = this;
    var stimulusTarget = event.currentTarget;

    Bulma.create('alert', {
      type: 'danger',
      title: I18n.t("community.settings.items.destroy.confirm.title"),
      body: I18n.t("community.settings.items.destroy.confirm.body"),
      confirm: {
        label: I18n.t("community.settings.items.destroy.confirm.btn.confirm"),
        onClick: function(){
          controller.stimulate("Admin::Items#destroy", stimulusTarget);
        }
      },
      cancel: I18n.t("community.settings.items.destroy.confirm.btn.cancel")
    });

    
    // this.hideLoading();
  }

  
}