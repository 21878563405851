
import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  // static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
    // console.log("datasets", this.element.dataset.series, this.element.dataset.labels)

    const label = this.element.dataset.label
    const size = this.element.dataset.size
    const color = this.element.dataset.color

    var flexStatCircleOptions = {
      series: [size],
      chart: {
        height: 130,
        type: 'radialBar',
        offsetX: -10,
        toolbar: {
          show: false,
        },
      },
      colors: [color],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '75%',
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
              fontSize: '12px',
              fontWeight: 400,
              offsetY: 5,
              color: '#a2a5b9',
            },
            value: {
              show: true,
              fontWeight: 600,
              fontFamily: 'Roboto, sans-serif',
              color: color,
              fontSize: '16px',
              offsetY: 5,
            },
          },
        },
      },
      labels: [label],
    }

    var flexStatCircle = new ApexCharts(
      this.element.querySelector('#flex-stat-circle'),
      flexStatCircleOptions,
    )
    flexStatCircle.render()
  }


}
