import ApplicationController from '../application_controller'
import Swiper from 'swiper/bundle';

export default class extends ApplicationController {
  static targets = ['input']
  
  connect(){

    this.inputTarget.value = 50;
    
    this.swiper = new Swiper(".mySwiper", {
      slidesPerView: 3,
      spaceBetween: 0,
      freeMode: true,
      pagination: false,
      initialSlide: this.inputTarget.value
    });

    const inputTarget = this.inputTarget;
    this.swiper.on('slideChange', function () {
      inputTarget.value = this.activeIndex;
    });

  }

  changeInput(){
    this.swiper.slideTo(this.inputTarget.value);
  }

  
}