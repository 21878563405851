// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="site">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Typewriter from 'typewriter-effect/dist/core';
import AOS from 'aos';
import Flickity from 'flickity';
import 'flickity/dist/flickity.css'

export default class extends Controller {
  static targets = [ "inputCoinName", "typewriter", "submitBtn" ]

  connect() {
    AOS.init({disable: 'mobile'});


    setTimeout(this.renderCarousel, 100)

    setTimeout(this.renderCarouselCases, 1000)
    
    if(this.hasTypewriterTarget){
      console.log(this.typewriterTarget.dataset["options"].split(","))
      new Typewriter("#"+this.typewriterTarget.id, {
        strings: this.typewriterTarget.dataset["options"].split(","),
        autoStart: true,
        loop: true
      });
    }
  }

  renderCarousel(){
    var elem = document.querySelector('.testimonial-carousel');
    if(elem){
      var flktyTestimonial = new Flickity( elem, {
        // options
        adaptiveHeight: true,
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false
      });
    }

    var elem = document.querySelector('.personas-carousel');
    if(elem){
      var flkty = new Flickity( elem, {
        // options
        // cellAlign: 'left',
        lazyLoad: true,
        adaptiveHeight: true,
        contain: true,
        imagesLoaded: true,
        pageDots: true
      });
      
    }
  }

  renderCarouselCases(){
    var elem = document.querySelector('.cases-carousel');
    if(elem){
      var flkty = new Flickity( elem, {
        // options
        // cellAlign: 'left',
        lazyLoad: true,
        adaptiveHeight: true,
        contain: true,
        pageDots: false,
        wrapAround: true,
        imagesLoaded: true,
      });

      flkty.on( 'staticClick', function( event, pointer, cellElement, cellIndex ) {
        var elem = event.target
        if(elem.classList.contains('is-selected'))
          window.open(elem.dataset['url'])
      });
    }
  }

  openCase(event){
    var elem = event.target
    if(elem.classList.contains('is-selected'))
      window.open(elem.dataset['url'])
  }

  hideTypewriter(){
    this.typewriterTarget.style.display = "none";
    this.inputCoinNameTarget.style.position = "relative";
  }

  showTypewriter(){
    if(this.inputCoinNameTarget.value.length == 0){
      this.typewriterTarget.style.display = "block";
      this.inputCoinNameTarget.style.position = "absolute";
    } else {
      this.typewriterTarget.style.display = "none";
      this.inputCoinNameTarget.style.position = "relative";
    }
    
  }

  showSubmit(){
    if(this.inputCoinNameTarget.value.length == 0){
      this.submitBtnTarget.classList.add("is-hidden");
    } else {
      this.submitBtnTarget.classList.remove("is-hidden");
    }
  }
}

