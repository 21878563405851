import ApplicationController from '../application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['nameInput']
  
  connect(){
    console.log('Snippet controller connected')
  }

  slugify(event){
    const name = event.target.value
    const slug = this.slugifyString(name)
    event.target.value = slug
  }

  slugifyString(string){
    return string
      .toLowerCase()
      .replace(/ /g,'_')
      .replace(/[^\w-]+/g,'')
  }
  

  
}