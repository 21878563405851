import ApplicationController from '../application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  // static targets = ['activeForm', 'rarityLabel', 'quantityInput']
  
  connect(){
    
    this.currentStep = parseInt(this.element.dataset.currentStep);
    console.log("patients onboard", this.currentStep)
  }

  nextSlide(event){
    event.preventDefault();

    if(this.currentStep == 5){
      return
    }

    const nextStep = parseInt(this.currentStep) + 1
    this.element.querySelector("#onboard_step_"+this.currentStep).classList.add("is-hidden")
    this.element.querySelector("#onboard_step_"+nextStep).classList.remove("is-hidden")
    this.currentStep = nextStep
  }

  prevSlide(event){
    event.preventDefault();
    
    if (this.currentStep == 1) {
      return
    }

    const prevStep = parseInt(this.currentStep) - 1
    this.element.querySelector("#onboard_step_"+this.currentStep).classList.add("is-hidden")
    this.element.querySelector("#onboard_step_"+prevStep).classList.remove("is-hidden")
    this.currentStep = prevStep
  }
  
}