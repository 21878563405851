import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template"];

  add(event) {
    event.preventDefault();
    console.log(this.templateTarget)
    event.currentTarget.insertAdjacentHTML(
      "beforebegin",
      this.templateTarget.innerHTML.replace(
        /__CHILD_INDEX__/g,
        new Date().getTime().toString()
      )
    );
  }

  remove(event) {
    event.preventDefault()
    // console.log(event)

    let wrapper = event.target.closest(".nested-fields")
    if(wrapper.dataset.newRecord == "true") {
        wrapper.remove()
    } else {
        wrapper.querySelector("input[name*='_destroy']").value = 1
        wrapper.style.display = "none"
    }
  }
}