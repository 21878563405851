
import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
    // console.log('connected')
    
    this.element.addEventListener("keyup", this.slugify);
    this.element.addEventListener("blur", this.slugify);
  }

  slugify(ev) {
    // return console.log('event', ev)

    ev.target.value = String(ev.target.value)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      // .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
  }
}
