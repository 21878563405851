
import { Controller } from "stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  // static targets = [ "input" ]
  // static values = { initialCountry: String }

  connect() {
    console.log("datasets", this.element.dataset.series, this.element.dataset.labels)

    const series = JSON.parse(this.element.dataset.series)
    const labels = JSON.parse(this.element.dataset.labels)
    const title = this.element.dataset.title
    const color = this.element.dataset.color
    const total = this.element.dataset.total

    Apex.grid = {
      padding: {
        right: 0,
        left: 0,
      },
    }

    Apex.dataLabels = {
      enabled: false,
    }

    // data for the sparklines that appear below header area
    var sparklineWidgetData = [
      472, 454, 547, 385, 562, 247, 652, 318, 379, 391, 622, 515, 355, 415, 358,
      271, 932, 534, 615, 278, 546, 435, 192, 465,
    ]

    var sparkWidget1 = {
      chart: {
        id: 'sparklineWidget1',
        group: 'sparklines',
        type: 'area',
        height: 130,
        sparkline: {
          enabled: true,
        },
      },
      colors: [color],
      stroke: {
        width: [2],
        curve: 'straight',
      },
      fill: {
        opacity: 1,
      },
      series: [
        {
          name: title,
          data: series,
        },
      ],
      labels: labels,
      yaxis: {
        min: 0,
      },
      xaxis: {
        type: 'datetime',
      },
      title: {
        text: title,
        offsetX: 5,
        style: {
          fontSize: '24px',
          cssClass: 'apexcharts-yaxis-title',
          color: '#a2a5b9',
        },
      },
      subtitle: {
        text: total,
        offsetX: 5,
        style: {
          fontSize: '24px',
          fontWeight: '600',
          cssClass: 'apexcharts-yaxis-title',
          color: '#797bf2',
        },
      },
    }
    new ApexCharts(
      this.element.querySelector('#widget-spark'),
      sparkWidget1,
    ).render()
  }


}
