import ApplicationController from '../application_controller'
// import Rails from '@rails/ujs';

export default class extends ApplicationController {
  // static targets = ['activeForm', 'rarityLabel', 'quantityInput']
  
  connect(){
    
  }

  confirm(event){
    // event.preventDefault();

    const modalCard = this.element.querySelector('.modal.is-active .modal-card')
    if(modalCard){ modalCard.classList.add('animate__bounceOutDown'); }

    const modal = this.element
    setTimeout(function(){
      modal.classList.remove('is-active');
      modal.innerHTML = "";
    }, 1000);

  }
  
}